<template>
    <div>
        <sn-table-group :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>

import { commonData } from '../../../sn-table-group-common';
import { selfData } from './model/sn-table-group';
import commonMethodMixin from '../../../commonMethodMixin';
import { mapActions } from 'vuex';
export default {
    name: 'equipmentdata_tilt_grid',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: 'equipmentdata_tilt_form',
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            // 筛选项
            itemId: '70304',
            bhlx: '2',
            keyword: '', // 关键字
            startValue: '',
            endValue: '',
        };
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            'DeleteDiseaseMonitorData',
            'GetDiseaseMonitorDataList'
        ]),
        async getTableData() {
            this.startValue = '';
            this.endValue = '';
            if (this.dateSearchData && this.dateSearchData.length > 0) {
                this.dateSearchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (item.value !==null && typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "startTime") {
                            this.startValue = `${item.value}`;
                        }else if(item.type === "endTime"){
                            this.endValue = `${item.value}`;
                        }
                    }
                });
            }

            this.tableGroupAttributes.loading = true;
            let res = await this.GetDiseaseMonitorDataList({
                itemId: this.itemId,
                keyWord: this.keyword,
                sort: null,
                sortorder: null,
                start: this.startValue,
                end: this.endValue,
                bhlx: this.bhlx,
                shzt: null,
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu
            })
            let tableData = [];
            if(res && res.Data && res.Data.length>0){
                if(res.Data[0] && res.Data[0].Data && res.Data[0].Data.length>0){
                    this.tableGroupAttributes.snTable.columns.columnsData[3] = {
                        label: `水平位移(${res.Data[0].Data[0].DW})`,
                        prop: "SPWY"
                    };
                    this.tableGroupAttributes.snTable.columns.columnsData[4] = {
                        label: `垂直位移(${res.Data[0].Data[0].DW})`,
                        prop: "ZZWY"
                    };
                    res.Data[0].Data.forEach(item0 => {
                        tableData.push({
                            "ID": item0.ID,
                            "JCX": item0.JCX,
                            "BHID": item0.BHID,
                            // "JCZ": item0.JCZ,
                            "JSCJ": item0.JSCJ,
                            "DW": item0.DW,
                            "BHMC": item0.BHMC,
                            "SHZT": item0.SHZT,
                            "RKSJ": item0.RKSJ,
                            "WZID": item0.WZID,
                            "SBID": item0.SBID,
                            "SBMC": item0.SBMC,
                            "CJSJ": item0.CJSJ,
                            "SPWY": item0.JCX==='水平位移'?item0.JCZ:0,
                            "ZZWY": item0.JCX==='垂直位移'?item0.JCZ:0,

                        });
                    })
                    if(res.Data[1] && res.Data[1].Data && res.Data[1].Data.length>0){
                        for(let i = 0; i < res.Data[1].Data.length; i++){
                            for(let j = 0; j < tableData.length; j++){
                                if(res.Data[1].Data[i].SBID === tableData[j].SBID && res.Data[1].Data[i].BHID === tableData[j].BHID && res.Data[1].Data[i].CJSJ === tableData[j].CJSJ){
                                    if(res.Data[1].Data[i].JCX === "水平位移"){
                                        tableData[j].SPWY = res.Data[1].Data[i].JCZ;
                                    }else{
                                        tableData[j].ZZWY = res.Data[1].Data[i].JCZ;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.tableGroupAttributes.snTable.table.data = tableData || [];
            this.tableGroupAttributes.snPage.count = Number(tableData.length) || 0;
            this.tableGroupAttributes.loading = false;
        },
        exportData() {
            let url = window.REQUEST_URL + 'MonitorData/GetDiseaseMonitorExport';
            let itemId = this.itemId;
            let keyWord = this.keyword;
            let start = this.startValue;
            let end = this.endValue;
            let bhlx = this.bhlx;

            let exportUrl = `${url}?itemId=${itemId}&keyWord=${keyWord}&start=${start}&end=${end}&bhlx=${bhlx}`;

            window.open(exportUrl);
        },
        // 删除数据
        async delData(id, done, instance, value) {
            let res = await this.DeleteDiseaseMonitorData({
                // bhidandrksj: [
                //     { cjsj: value.CJSJ, bhid: value.BHID }
                // ],
                // itemCode: this.itemId,
                // bhlx: this.bhlx
                id: value.ID
            });
            this.delOver(res, done);
        },
    },
};
</script>
<style lang="scss" scoped>

</style>